import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import {OrbitControls}  from 'three/examples/jsm/controls/OrbitControls';

import * as THREE from 'three';

export function OBJ3DLoader(payload) {
    //DECLARATIONS
    let object;
    //CONTAINER

    //CAMERA PERSPECTIVE
    const camera = new THREE.PerspectiveCamera( 45, payload.width / payload.height, .00001,1000 );
    //MODEL POSITION
    camera.position.z = payload.viewSize;
    
    // SCENE
    const scene = new THREE.Scene();
    const ambientLight = new THREE.AmbientLight( 0xcccccc, 0.1 );
    scene.add( ambientLight );
    
    const pointLight = new THREE.PointLight( 0xffffff, 1 );
    camera.add( pointLight );
    scene.add( camera );

    // LOADING MANAGER
    const manager = new THREE.LoadingManager(()=>{
        object.traverse((child) => { 
            if( child.isMesh ){ 
                child.material.map = texture
            }
        });
        object.position.x = payload.leftRightPosition;
        object.position.y = payload.upDownPosition;
        scene.add(object);
    });
    
    // MODEL TEXTURE
    const textureLoader = new THREE.TextureLoader( manager );
    const texture = textureLoader.load(payload.texture);
    
    // MODEL LOADING
    const loader = new OBJLoader( manager );
    loader.load(payload.model,
    (obj) => {
        object = obj
    },(xhr) =>{
        if ( xhr.lengthComputable ) {
            const percentComplete = xhr.loaded / xhr.total * 100;
            console.log( 'model ' + Math.round( percentComplete, 2 ) + '% downloaded' );
        }
    },(error)=>{
        console.log('Something went wrong while loading',error)
    });
    

    //CREATE WEBGL
    const container=document.querySelector(payload.container);
    const renderer = new THREE.WebGLRenderer({ alpha: true } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( payload.width ?? container.offsetWidth, payload.height ?? container.offsetHeight );
    renderer.setClearColor(0xffffff, 0);
    container.appendChild( renderer.domElement );

    const controls = new OrbitControls( camera, renderer.domElement );
    controls.enableZoom=false;
    controls.autoRotate=true;
    controls.autoRotateSpeed=payload.autoRotationSpeed;
    if(payload.isZoomEnable){
        //controls.autoRotate=false;
        controls.enableZoom=payload.isZoomEnable;
        controls.maxDistance=payload.maxZoom;
        controls.minDistance=payload.minZoom;    
    }
    controls.update();
    
    //RESIZE
    window.addEventListener( 'resize', ()=>{
        camera.aspect = container.offsetWidth / container.offsetHeight;
        camera.updateProjectionMatrix();
        renderer.setSize( container.offsetWidth, container.offsetHeight );
    });
    //document.addEventListener( 'mousemove', onDocumentMouseMove );
   
    //RENDER FRAME
    animate();
    function animate() {
        requestAnimationFrame( animate );
        controls.update();
        camera.lookAt( scene.position );
        renderer.render( scene, camera );
    }
}


export function GLTF3DLoader(payload) {
    //DECLARATIONS
    let object;
    //CONTAINER

    //CAMERA PERSPECTIVE
    const camera = new THREE.PerspectiveCamera( 45, payload.width / payload.height, .00001,1000 );
    //MODEL POSITION
    camera.position.z = payload.viewSize;

    // SCENE
    const scene = new THREE.Scene();
    const ambientLight = new THREE.AmbientLight( 0xcccccc, 0.1 );
    scene.add( ambientLight );

    const pointLight = new THREE.PointLight( 0xffffff, 1 );
    camera.add( pointLight );
    scene.add( camera );
    // MODEL LOADING

    const loader = new GLTFLoader();
    loader.load(payload.model,
    (obj) => {
        scene.add(...obj.scene.children);
    },(xhr) =>{
        if ( xhr.lengthComputable ) {
            const percentComplete = xhr.loaded / xhr.total * 100;
            console.log( 'model ' + Math.round( percentComplete, 2 ) + '% downloaded' );
        }
    },(error)=>{
        console.log('Something went wrong while loading',error)
    });


    //CREATE WEBGL
    const container=document.querySelector(payload.container);
    const renderer = new THREE.WebGLRenderer({ alpha: true } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( payload.width ?? container.offsetWidth, payload.height ?? container.offsetHeight );
    renderer.setClearColor(0xffffff, 0);
    container.appendChild( renderer.domElement );

    const controls = new OrbitControls( camera, renderer.domElement );
    controls.enableZoom=payload.zoom;
    controls.autoRotate=true;
    controls.autoRotateSpeed=payload.autoRotationSpeed;
    if(payload.isZoomEnable){
        //controls.autoRotate=false;
        controls.enableZoom=payload.isZoomEnable;
        controls.maxDistance=payload.maxZoom;
        controls.minDistance=payload.minZoom;    
    }
    controls.update();

    //RESIZE
    window.addEventListener( 'resize', ()=>{
        camera.aspect = container.offsetWidth / container.offsetHeight;
        camera.updateProjectionMatrix();
        renderer.setSize( container.offsetWidth, container.offsetHeight );
    });
    //document.addEventListener( 'mousemove', onDocumentMouseMove );

    //RENDER FRAME
    animate();
    function animate() {
        requestAnimationFrame( animate );
        controls.update();
        camera.lookAt( scene.position );
        renderer.render( scene, camera );
    }
}

