import {OBJ3DLoader,GLTF3DLoader} from './assembly/helper';
import '../resources/assets/bootstrap/bootstrap.min.css';
import './style.css';
import 'animate.css';


// document.addEventListener('contextmenu', function(e) {
//     e.preventDefault();
// });
// console.log(document.readyState);
// if (document.readyState === 'interactive') {
//     document.getElementsByClassName('loader')[0].style.display='none';
// }


  
  
  
  
  
  


//BUTTONS
const landingViewRevert=document.getElementsByClassName("btn-landing-view")[0];
const productViewInitiative=document.getElementsByClassName("btn-product-view")[0];
//const productDetailInitiative=document.querySelectorAll('.btn-product-detail');
const productViewRevert=document.getElementsByClassName("btn-products-revert")[0];
const productLeftSlide=document.getElementsByClassName("btn-slide-left")[0];
const productRightSlide=document.getElementsByClassName("btn-slide-right")[0];

const productStoryInitiative=document.getElementsByClassName("btn-product-story")[0];
const productcontactInitiative=document.getElementsByClassName("btn-product-contact-us")[0];

//SECTIONS
const productSection=document.getElementsByClassName("product-section")[0];
const landingSection=document.getElementsByClassName("landing-section")[0];
const detailSection=document.getElementsByClassName("product-detail-section")[0];

const storySection=document.getElementsByClassName("story-section")[0];
const contactSection=document.getElementsByClassName("contact-section")[0];

//LANDING MODEL
OBJ3DLoader({
    viewSize:3,
    leftRightPosition:0,
    upDownPosition:-1.2,
    positionz:-2,
    texture:'assets/textures/mars-landing-texture.jpg',
    model:'assets/sphere/model.obj',
    height:1500,
    width:1500,
    container:'.galaxy-landing',
    autoRotationSpeed:.7,
});

//PRODUCTS
GLTF3DLoader({
    viewSize:-10,
    leftRightPosition:0,
    upDownPosition:-1.2,
    positionz:-2,
    texture:'',
    model:'assets/nabula.glb',
    height:500,
    width:1200,
    container:'.galaxy-products-1',
    autoRotationSpeed:3,
    zoom:true
});

/*
// GLTF3DLoader({
//     viewSize:-10,
//     leftRightPosition:0,
//     upDownPosition:-1.2,
//     positionz:-2,
//     texture:'earth/textures/Material_50_metallicRoughness.png',
//     model:'earth/Earth.gltf',
//     height:500,
//     width:1200,
//     container:'.galaxy-test',
//     autoRotationSpeed:.1,
//     zoom:true
// });


GLTF3DLoader({
    viewSize:-5,loading="eager"
    leftRightPosition:0,
    upDownPosition:-1.2,
    positionz:-2,
    texture:'',
    model:'assets/nabula.glb',
    height:500,
    width:1200,
    container:'.galaxy-products-2',
    autoRotationSpeed:.7,
    zoom:true
});
/*GLTF3DLoader({
    viewSize:3,
    leftRightPosition:0,
    upDownPosition:-1.2,
    positionz:-2,
    texture:'assets/gltf/textures/',
    model:'assets/gltf/source/test2.gltf',
    height:500,
    width:500,
    container:'.galaxy-products-1',
    autoRotationSpeed:.7,
});
OBJ3DLoader({
    viewSize:3,
    leftRightPosition:0,
    upDownPosition:-1.2,
    positionz:-1,
    texture:'assets/textures/mars-texture.jpg',
    model:'assets/sphere/model.obj',
    height:500,
    width:500,
    container:'.galaxy-products-2',
    autoRotationSpeed:.7,
});
OBJ3DLoader({
    viewSize:3,
    leftRightPosition:0,
    upDownPosition:-1.2,
    positionz:-1,
    texture:'assets/textures/moon-texture.jpg',
    model:'assets/sphere/model.obj',
    height:500,
    width:500,
    container:'.galaxy-products-3',
    autoRotationSpeed:.7,
    
});
*/
productViewInitiative.addEventListener("click", () => {
    if(landingSection.style.display='block'){
        const element = document.querySelector('.animate__fadeInRight');
        element.classList.add('animate__fadeOutTopLeft');
        setTimeout(()=>{
            productViewRevert.classList.add('active');
            landingSection.style.display='none';
            productSection.style.display='block';
            element.classList.remove('animate__fadeOutTopLeft');
        },500)
    }    
});

productStoryInitiative.addEventListener("click", () => {
    if(storySection.style.display='none'){
        const element = document.querySelectorAll(".galaxy-product-detail > canvas")[0];
        if(element){
            element.parentNode.removeChild(element);
        }    
        storySection.style.display='block';
        contactSection.style.display='none';
        landingSection.style.display='none';
        productSection.style.display='none';
        productViewRevert.classList.remove('active');
    }    
});

productcontactInitiative.addEventListener("click", () => {
    if(contactSection.style.display='none'){
        const element = document.querySelectorAll(".galaxy-product-detail > canvas")[0];
        if(element){
            element.parentNode.removeChild(element);
        }    
        contactSection.style.display='block';
        storySection.style.display='none';
        landingSection.style.display='none';
        productSection.style.display='none';
        productViewRevert.classList.remove('active');
    }    
});

landingViewRevert.addEventListener("click", () => {
    /*if(landingSection.style.display='none'){
        const element = document.querySelectorAll(".galaxy-product-detail > canvas")[0];
        if(element){
            element.parentNode.removeChild(element);
        }    
        landingSection.style.display='block';
        productSection.style.display='none';
        productViewRevert.classList.remove('active');
    }*/

    const element = document.querySelectorAll(".galaxy-product-detail > canvas")[0];
    if(element){
        element.parentNode.removeChild(element);
    }    
    landingSection.style.display='block';
    productSection.style.display='none';
    productViewRevert.classList.remove('active');
    var planetSection = document.getElementById("planetSection");
    var headerbgElement = document.querySelector(".headerbg");
    planetSection.style.display = "block";
    headerbgElement.style.height = "200rem"; // Show section and set height to 200rem

    /*if (planetSection.style.display === "none") {
      planetSection.style.display = "block";
      headerbgElement.style.height = "200rem"; // Show section and set height to 200rem
    } 
    else
     {
      planetSection.style.display = "none";
      planetSection.classList.add('inactive');
      headerbgElement.style.height = "60rem"; // Hide section and set height to 40rem
    } */   
});
/*
productDetailInitiative.forEach(el => el.addEventListener('click', event => {
    OBJ3DLoader({
        viewSize:3,
        leftRightPosition:0,
        upDownPosition:-1.3,
        positionz:-1,
        texture:event.target.getAttribute('data-texture'),
        model:'assets/sphere/model.obj',
        height:window.innerHeight,
        width:window.innerWidth,
        container:'.galaxy-product-detail',
        autoRotationSpeed:.7,
        isZoomEnable:true,
        maxZoom:5,
        minZoom:.5    
    });
    if(detailSection.style.display='none'){
        productViewRevert.classList.remove('active');
        detailSection.style.display='block';
        productSection.style.display='none';
    }
}));*/

productViewRevert.addEventListener("click", () => {
    if(landingSection.style.display==='none' && productSection.style.display==='none'){
        const element = document.querySelectorAll(".galaxy-product-detail > canvas")[0];
        if(element){
            element.parentNode.removeChild(element);
        }
        productViewRevert.classList.add('active');
        productSection.style.display='block';
        detailSection.style.display='none';
    }    
});

function slideModels(animation){

    const orderOne=document.getElementsByClassName("order-1")[0]
    const orderTwo=document.getElementsByClassName("order-2")[0]
    //const orderThree=document.getElementsByClassName("order-3")[0] Hide this model ---
    const orderFour=document.getElementsByClassName("order-4")[0]
    const orderFive=document.getElementsByClassName("order-5")[0]
    const orderSix=document.getElementsByClassName("order-6")[0]
    const orderSeven=document.getElementsByClassName("order-7")[0]

    orderOne.classList.remove('order-1');
    orderOne.classList.add('order-4');
    orderOne.classList.remove('active');
    
    orderTwo.classList.add('order-1');
    orderTwo.classList.add('active');
    //orderThree.classList.add(animation); Hide this model ---
    orderTwo.classList.remove('order-2');

    //orderThree.classList.remove('order-3'); Hide this model ---
// orderThree.classList.add('order-2'); Hide this model ---

    orderFour.classList.remove('order-4');
    orderFour.classList.add('order-5'); // orderFour.classList.add('order-3');

    orderFive.classList.remove('order-5');
    orderFive.classList.add('order-6');

    orderSix.classList.remove('order-6');
    orderSix.classList.add('order-7');

    orderSeven.classList.remove('order-7');
    orderSeven.classList.add('order-2');
}

productLeftSlide.addEventListener("click",()=>slideModels('animate__slideInRight'));
productRightSlide.addEventListener("click",()=>slideModels('animate__slideInLeft'));


